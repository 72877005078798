.header {
    padding:0px 57px;
    background-color: white;
    width: 100%;
    height: 97px;
}

.header .logo{
    width: 203px;
    height: 35px;
    margin-left: 20px;
    margin-right: auto;
    background: #ababab;
    padding: 10px;
}

.header .menu {
    margin-left: auto;
}


ul.menu {
    list-style-type: none;
}

ul.menu li {
    margin-right: 50px;
    font-size: 20px;
    line-height: 28px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
}

li.active {
    color:#FF5C5C;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.header-sign {
    margin-left: auto;
    width: 180px;
    display: flex;
    flex-direction: row;
    flex:0.5;
    justify-content: flex-end;
    padding-right: 40px;
}

.login {
    width: 69px;
    height: 34px;
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 32px;
    font-size: 15px;
    line-height: 21px;
}

.register {
    border: 1px solid #FC66FF;
    border-radius: 12px;
    width: 108px;
    min-width: 108px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    line-height: 21px;
}

.close-button {
    width: 54px;
    height: 54px;
    position:absolute;
    right:10px;
    top:10px;
}

.close-button img {
    width: 14px;
    height: 14px;
}

.h-s-active {
    color: #000;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

.h-s-inactive{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color:#ACACAC;
}
.h-s-top {
    height: 72px;
    align-items: center;
    justify-content: center;
    padding-left: 32px;
}

.register-submit {
    width: 320px;
    height: 45px;
    left: 35px;
    top: 8px;

    /* Linear */

    background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
    border-radius: 45px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: -0.24px;

    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    display: flex;
}

.register-input {
    border: 0px solid #DCDCDC;
    border-radius: 8px;
    padding:11px;
    height: 43px;

}
.register-form-row{
    margin:8px 32px;
    border-bottom: 1px solid #DCDCDC;   
    align-items: center;
}

.register-captcha {
    width: 111px;
    height: 43px;
    left: 0px;
    top: 0px;

    /* Linear */
    background: linear-gradient(91.22deg, #FC66FF 2.91%, #FF5C5C 95.46%);
    border-radius: 8px;
}
.menuitem-icon {
    width: 18px;
    height: 18px;
    margin-right:6px;
}
.btn-inactive {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #ABABAB
}

.linkbtn {
    color:#2974EB;
    padding:0px 10px;
    font-size: 15px;
    cursor: pointer;
}
.phone-area {
    border-right: 1.5px solid #D9D9D9;
    padding: 5px 20px;
}
.code-btn {
    cursor: pointer;
    width: 110px;
    height: 39px;
    left: 0px;
    top: 0px;

    /* Linear */

    background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
    border-radius: 45px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: -0.24px;

    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    display: flex;
}

.search-input-continer {
    display: flex;
    background-color: #ededed;
    border-radius: 60px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #777777;
    width: 231px;
    height: 49px;
    align-items: center;
    padding:0px  16px;
}

.search-input {
    border-width: 0px;
    background-color: #ededed;
    border-radius: 60px;
    flex: 1;
}

.search-icon {
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 16;
}

.dropdownimg {
    width: 36px;
    height: 36px;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 84px;
    height: 185px;

    background: #FFFFFF;
    box-shadow: 2px 4px 8px rgba(165, 165, 165, 0.3);
    border-radius: 4px;
}

.dropdown-menu .item {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#777;
    cursor: pointer;
}

.dropdown-menu .item.disabled {
    color: #bcbcbc;
}


.create-link {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    /* identical to box height */


    /* Style */

    color: #2974EB;
    margin-right: 16px;
}